import React from 'react'
import { Link } from 'gatsby'

import './Nav.scss'

const Nav = (props) => 
(
    <nav id="nav" className={props.sticky ? 'navTwo' : 'navOne'}>

        <img 
            src={require('../../assets/logo_small.svg')} 
            className='logo_small'
            alt='logo_small' 
        />     

        <Link 
            to="/"
            className='menu sobrenos'
        >
            SOBRE NÓS
        </Link>
        <Link 
            to="/services" 
            className='menu services'
        >
            SERVIÇOS
        </Link>
        <Link 
            to="/#anchor_section4" 
            className='menu clientes'
        >
            CLIENTES
        </Link>
        <Link 
            to="/services/#section3" 
            className='menu contato'
        >
            CONTATO
        </Link>        

        <div className='barra' />

    </nav>
)

export default Nav
