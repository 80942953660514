import React, { Component } from 'react'

import MenuItem             from './MenuItem'
import MenuButton           from './MenuButton'
import Menu                 from './Menu'

import './main.scss'


class Main extends Component 
{
    state=
    {
        menuOpen:false,
    }
    
    onMenuClick = () => 
    {
        this.setState(
            {
                menuOpen:!this.state.menuOpen
            }
        )
    }

    onLinkClick = () => 
    {
        this.setState(
            {
                menuOpen: false
            }
        )
    }

    
    render()
    {
        const menu =
        [
            {
                'val': 'SOBRE NÓS',
                'path': './'
            },
            {
                'val': 'SERVIÇOS',
                'path': './services'
            },
            {
                'val': 'CLIENTES',
                'path': './#anchor_section4'
            },
            {
                'val': 'CONTATO',
                'path': './services/#section3'
            }
        ]

        const menuItems = menu.map((val,index)=>
            {
                return (
                    <MenuItem 
                        key={index} 
                        delay={`${index * 0.1}s`}
                        onClick={()=>{this.onLinkClick()}}
                        path={val.path}
                    >
                        {val.val}                
                    </MenuItem>
                )
            }
        )
      
        return(
            <div>

                <div className='header_container'>

                    <MenuButton 
                        open={this.state.menuOpen} 
                        onClick={()=>this.onMenuClick()} 
                    /> 

                </div>

                <Menu open={this.state.menuOpen}>
                    {menuItems}
                </Menu>

            </div>
        )
    }
}


  export default Main