import React, { Component } from 'react'
import { Link }             from 'gatsby'

import './menuItem.scss'


class MenuItem extends Component
{    
    render()
    {
        const styles=
        {
            opacity: 0,
            animation: '1s appear forwards',
            animationDelay:this.props.delay
        }

        return(
            <div style={styles}>

                <Link 
                    to={this.props.path}
                    className='menuItem'
                    onClick={this.props.onClick}
                >
                    {this.props.children}
                </Link>

            </div>  
        )
    }
}

export default MenuItem